<template>
  <div>
    <h2 class="mb-4">Список распродаж</h2>

    <data-table url="discounts/sales" :headers="headers" item-key="name" clickable-rows @click:row="showSale">
      <template v-slot:[`item.name`]="{ item: sale }">
        <router-link :to="{ name: 'sales-id', params: { id: sale.name } }">
          {{ sale.name }}
        </router-link>
      </template>

      <template v-slot:[`item.isActive`]="{ item: sale }">{{ sale.isActive | yesNo }}</template>

      <template v-slot:[`item.beginsAt`]="{ item: sale }">{{ sale.beginsAt | dateTime }}</template>
      <template v-slot:[`item.endsAt`]="{ item: sale }">{{ sale.endsAt | dateTime }}</template>

      <template v-slot:[`item.updatedAt`]="{ item: sale }">{{ sale.updatedAt | dateTime }}</template>
    </data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: "Имя", value: "name", sortable: false },
        { text: "Описание", value: "description", sortable: false },
        { text: "Активен", value: "isActive", sortable: false },

        { text: "Начало", value: "beginsAt", sortable: false },
        { text: "Окончание", value: "endsAt", sortable: false },

        { text: "Дата обновления", value: "updatedAt", sortable: false }
      ]
    };
  },
  methods: {
    showSale(sale) {
      this.$router.push({ name: "sales-id", params: { id: sale.name } });
    }
  }
};
</script>
